import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"
import "../../graphql/fragments"
import moment from "moment"
import { useMediaPredicate } from "react-media-hook"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { CoverMain } from "../../components/cover-main/cover-main"
import { HeadingBasic } from "../../components/heading/heading"
import { Section, Container } from "../../components/grid/grid"
import { Button } from "../../components/buttons/buttons"
import ButtonCalendar from "../../assets/img/buttondownload2023.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Calendar2024 = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query{
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "calendar2024", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
            ... on WordPress_CoreShortcodeBlock {
              attributes {
                text
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
              ... on WordPress_CoreShortcodeBlock {
                attributes {
                  text
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
        calendar: fileCategory(id: "calendar", idType: SLUG) {
          slug
          filesPdf(where: {orderby: {field: DATE, order: DESC}}, first: 10) {
            edges {
              cursor
              node {
                title
                date
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const seo = staticData.wordPress.page?.seo
  const wordPress = staticData.wordPress.page
  const pdfFiles = staticData.wordPress.calendar?.filesPdf?.edges
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  let pdfItems = []
  let coverItems = []
  // eslint-disable-next-line
  pdfFiles?.length > 0 &&
  pdfFiles?.map(item => {
    if (item?.node?.acfFile?.year === 2024) {
      pdfItems.push({
        title: item?.node?.title || "Title",
        linkTo:
          item?.node?.acfFile?.file !== null ?
          item?.node?.acfFile?.file?.mediaItemUrl : null,
        fileId:
          item?.node?.acfFile?.file != null && item?.node?.acfFile?.file?.databaseId,
        fileSize:
          item?.node?.acfFile?.file !== null && item?.node?.acfFile?.file?.filesize,
        date: `${moment(item?.node?.date).format("DD MMMM YYYY")} • ${moment(
          item.node.date
        ).format("HH:mm")}`,
      })
    }
    return 1
  })
  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
    }
  })

  //const cover = wordPress?.blocks?.find((item) => item.name === "core/cover")
  const featuredImage = staticData?.wordPress?.page?.featuredImage?.mediaItemUrl

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
      }
    })
  }, [])

  useEffect(() => {
    if(resizeScreen){
      document.querySelector("img.btn-img-calendar").style.width = "100%"
    }else{
      // document.querySelector("img.btn-img-calendar")?.style.width = "30%"
    }
  }, [])

  return(
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={location?.href}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <CoverMain
        h1={""}
        img={
          resizeScreen 
            ? coverItems[1]?.url
            : coverItems[0]?.url
          }
        alt={wordPress?.title}
        className={`cover-md ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
      />
      {wordPress?.blocks.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container container-md">
                <div className={i === 0 ? "py-main pb-3 pt-4" : "pt-main"}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core/paragraph":
            return (
              <div
                key={`headingbasic-${i}`}
                className={`py-main pb-3 pt-3`}
              >
                <div className="container container-md">
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "core/shortcode":
            if(item.attributes.text === "calendar"){
              return(
                <Container className="container-md py-main pb-5 pt-5">
                  <div className="col-md-12">
                    <a href={pdfItems[0]?.linkTo || pdfItems[0]?.linkTo !== undefined ? pdfItems[0]?.linkTo : "/404"} target="_blank">
                      <img src={ButtonCalendar} alt="Kalender 2023 Sinar Mas Land" className="btn-img-calendar" style={resizeScreen ? {width: "100%"} : {width: "30%"}}/>
                    </a>
                  </div>
                </Container>
              )
            }
          default:
            return <></>
        }
      })}
    </Layout>
  )
}

export default Calendar2024